<template>
  <v-select
    :label="label"
    :value="value"
    :items="optionsTranslated"
    @change="change"
    filled
    background-color="transparent"
    hide-details
  >
  </v-select>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value", "options"],
  computed: {
    optionsTranslated() {
      if (this.options) {
        return this.options.map((opt) => {
          return {
            text:
              opt.text.indexOf("$vuetify.key.") == 0
                ? this.$vuetify.lang.t(opt.text)
                : opt.text,
            value: opt.value,
          };
        });
      }
      return [];
    },
  },
  methods: {
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>
